import React from 'react';
import Layout from '../components/Layout';
import Logo from '../images/LogoLight.png';
import Screenshot from '../images/Screenshot.png';
import { ArrowSmRightIcon } from '@heroicons/react/outline';


const IndexPage = () => {
  const seo = {
    title: 'Save My Wordle',
    description: "Save your Wordle scores and compete with friends.",
    canonical: "https://savemywordle.com"
  }


  return (
    <Layout
      seo={seo}
    >
      <div className="bg-black pb-8 sm:pb-12 lg:pb-12">
        <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-48">
          <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
            <div>
              <div>
                <img
                  className="h-20 w-auto"
                  src={Logo}
                  alt="Save My Wordle Logo"
                />
              </div>
              <div className="mt-4">
                {/* <div>
                  <a href="#" className="inline-flex space-x-4">
                    <span className="rounded bg-indigo-50 px-2.5 py-1 text-xs font-semibold text-indigo-600 tracking-wide uppercase">
                      What's new
                    </span>
                    <span className="inline-flex items-center text-sm font-medium text-indigo-600 space-x-1">
                      <span>Just shipped version 0.1.0</span>
                      <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </a>
                </div> */}
                <div className="mt-6 sm:max-w-xl">
                  <h1 className="text-4xl font-extrabold text-white tracking-tight sm:text-5xl">
                    Save your Wordle scores
                  </h1>
                  <p className="mt-4 text-xl text-gray-500">
                    As well as track your stats, create leagues, and compete against friends.
                  </p>
                </div>
                <div className="mt-6 flex gap-x-2">
                  <a
                    href="https://app.savemywordle.com/login"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800"
                  >
                    Log In
                  </a>
                  <a
                    href="https://app.savemywordle.com/register"
                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-white hover:bg-gray-300"
                  >
                    Get Started
                    <ArrowSmRightIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
            <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
              <div className="hidden sm:block">
                <div className="absolute inset-y-0 left-1/2 w-screen bg-gray-900 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full" />
                {/* <svg
                  className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0"
                  width={404}
                  height={392}
                  fill="none"
                  viewBox="0 0 404 392"
                >
                  <defs>
                    <pattern
                      id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect x={0} y={0} width={4} height={4} className="text-green-900" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width={404} height={392} fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
                </svg> */}
              </div>
              <div className="relative pl-4 -mr-44 sm:-mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
                <img
                  className="w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:h-full lg:w-auto lg:max-w-none"
                  src={Screenshot}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 px-6 lg:px-8">
        <div className="mx-auto">
          <div className="text-center">
            <div className="flex justify-center mb-4">
              <img src={Logo} alt="Save My Wordle Logo" className="h-28 w-28 sm:h-40 sm:w-40" />
            </div>
            <h1 className="text-4xl font-bold">Save your Wordle scores</h1>
            <h4 className="text-lg font-normal text-gray-500 mt-2">As well as track your stats, create leagues, and compete against friends.</h4>
            <div className="mt-4 flex justify-center gap-x-2">
              <a
                href="https://app.savemywordle.com/login"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-black bg-gray-200 hover:bg-gray-300"
              >
                Log In
              </a>
              <a
                href="https://app.savemywordle.com/register"
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-black hover:bg-gray-800"
              >
                Get Started
                <ArrowSmRightIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
              </a>
            </div>
          </div>
        </div>
      </div> */}
    </Layout>
  )
}

export default IndexPage;

